
import { ref, onBeforeMount } from "vue";

export default {
  name: "FooterSection",
  setup() {
    const showNavbar = ref(false);
    const windowSize = ref(0);

    function toggleNavbar() {
      showNavbar.value = !showNavbar.value;
    }

    onBeforeMount(() => {
      windowSize.value = window.innerWidth;
    }),
      window.addEventListener("resize", () => {
        windowSize.value = window.innerWidth;
      });

    return {
      showNavbar,
      toggleNavbar,
      windowSize,
    };
  },
};
