
import { Routes } from "@/types/Routes";
import { ref, onMounted, onBeforeMount } from "vue";
  
export default {
    name: "BottomSection",
    components: {},
    setup() {
        const scrollPosition = ref(0);
        function updateScroll() {
        scrollPosition.value = window.scrollY;
        }

        const windowSize = ref(window.innerWidth);

        onBeforeMount(() => {
          windowSize.value = window.innerWidth;
        }),
          window.addEventListener("resize", () => {
            windowSize.value = window.innerWidth;
          });

        onMounted(() => {
        window.addEventListener("scroll", updateScroll);
        });

        const scrollToContent = () => {
        const anchor = document.querySelector("#scrolllHere");
        if (anchor) {
            anchor.scrollIntoView({ behavior: "smooth" });
        }
        };

        return {
            scrollPosition,
            updateScroll,
            scrollToContent,
            Routes,
        };
    },
  };
  