import { createStore } from "vuex";
import router from "../router";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  sendEmailVerification,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";
import { db, googleProvider, appleProvider } from "../firebase";
import { getDoc, doc, setDoc } from "firebase/firestore";
// eslint-disable-next-line
const firebase: any = require("../firebase");
const auth = firebase.auth;
import { State } from "./types/State";
import { TourStatus } from "./types/TourStatus";
import { Routes } from "@/types/Routes";
import checkPremium from "@/logic/checkPremium";
import { sendEvent } from "@/logic/analytics";
import { AllowedEvents } from "@/types/AllowedEvents";

export default createStore<State>({
  state: {
    user: null,
    isPremium: false,
    haveBeenPremium: true,
    handledByApple: false,
  },
  getters: {},
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    CLEAR_USER(state) {
      state.user = null;
    },
    SET_PREMIUM(state, boolean) {
      state.isPremium = boolean;
    },
    SET_HAVE_BEEN_PREMIUM(state, boolean) {
      state.haveBeenPremium = boolean;
    },
    SET_HANDLED_BY_APPLE(state, boolean) {
      state.handledByApple = boolean;
    },
  },
  actions: {
    async login({ commit }, details) {
      const { email, password } = details;

      try {
        await signInWithEmailAndPassword(auth, email, password);
      } catch (error: any) {
        console.log(error);
        switch (error.code) {
          case "auth/user-not-found":
            alert("Unable to find user");
            break;
          case "auth/wrong-password":
            alert("Wrong password");
            break;
          default:
            alert("Something went wrong");
        }

        return;
      }

      commit("SET_USER", auth.currentUser);
    },

    async signinProvider({ commit }, obj) {
      try {
        if (obj.provider === "google") {
          if (obj.device) {
            await signInWithRedirect(auth, googleProvider);
          } else {
            signInWithPopup(auth, googleProvider).then((result) => {
              const user = result.user;
              commit("SET_USER", user);
              router.replace(Routes.SIGNUPWELCOME);
            });
          }
        }

        if (obj.provider === "apple") {
          if (obj.device) {
            await signInWithRedirect(auth, appleProvider);
          } else {
            signInWithPopup(auth, appleProvider).then((result) => {
              const user = result.user;
              commit("SET_USER", user);
              router.replace(Routes.SIGNUPWELCOME);
            });
          }
        }
      } catch (error: any) {
        console.log(error);
      }
    },

    async register({ commit }, details) {
      const { email, password } = details;

      const createAccount = createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      try {
        await createAccount.then(async () => {
          commit("SET_USER", auth.currentUser);
          await sendEmailVerification(auth.currentUser, {
            url: window.location.href + "/welcome",
          });
        });

        router.replace(Routes.SIGNUPVERIFY);
        return "success";
      } catch (error: any) {
        console.log(error);
        switch (error.code) {
          case "auth/email-already-in-use":
            alert("Email already in use");
            break;
          case "auth/invalid-email":
            alert("Invalid email");
            break;
          case "auth/operation-not-allowed":
            alert("Operation not allowed");
            break;
          case "auth/weak-password":
            alert("Password is too weak");
            break;
          default:
            alert("Something went wrong");
        }
        return;
      }
    },

    async fetchUser({ commit }) {
      try {
        auth.onAuthStateChanged(async (user: any) => {
          console.log("user", user);

          if (user === null) {
            commit("CLEAR_USER");
          } else {
            commit("SET_USER", user);

            const premiumRes = await checkPremium(this);

            if (!premiumRes) {
              alert("Failed to check subscription, please try again later");
              await new Promise((r) => setTimeout(r, 5000));
              await this.dispatch("logout");
            }

            if (user.emailVerified == false) {
              router.replace(Routes.SIGNUPVERIFY);
              return;
            }

            await auth.currentUser.getIdToken(true);
            await auth.currentUser.reload();

            this.dispatch("routeChecker", router.currentRoute.value.path);

            await sendEvent(this, AllowedEvents.SIGNED_IN);
          }
        });
      } catch (error) {
        console.log(error);
      }
    },

    async routeChecker({ commit }, route: Routes) {
      try {
        const user = auth.currentUser;
        void commit;

        console.log("route", route);

        if (!user || route == Routes.LANDING) {
          return;
        }

        const userDoc = await getDoc(doc(db, "users", user.uid));

        if (userDoc.exists()) {
          const tourStatus = userDoc.data().tourStatus;

          switch (tourStatus) {
            case TourStatus.WELCOME:
              router.replace(Routes.SIGNUPWELCOME);
              break;
            case TourStatus.PLAN:
              await setDoc(
                doc(db, "users", user.uid),
                {
                  tourStatus: TourStatus.THIS_DOWNLOAD,
                },
                { merge: true }
              );
              router.replace(Routes.SIGNUPTHISDOWNLOAD);
              break;
            case TourStatus.THIS_DOWNLOAD:
              router.replace(Routes.SIGNUPTHISDOWNLOAD);
              break;
            case TourStatus.REMOTE_DOWNLOAD:
              router.replace(Routes.SIGNUPREMOTEDOWNLOAD);
              break;
            case TourStatus.COMPLETE:
              router.replace(Routes.DOWNLOAD);
              break;
            default:
              router.replace(Routes.SIGNUPWELCOME);
              break;
          }
        } else {
          // Jag får inte skriva här av någon anledning, den bli blockad av emailVerified() funktionen i firebase.
          // Något lurt med hur den verifierar det
          await setDoc(
            doc(db, "users", user.uid),
            {
              tourStatus: TourStatus.WELCOME,
            },
            { merge: true }
          );
          router.replace(Routes.SIGNUPWELCOME);
          return;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async verifyEmail() {
      const email = auth.currentUser.email;

      const sendEmail = sendEmailVerification(auth, email);

      await sendEmail
        .then(() => {
          alert("Verify email sent, please check your inbox");
          setTimeout(() => {
            this.dispatch("logout");
            router.replace(Routes.SIGNIN);
          }, 30000);
          return true;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
    },
    async logout({ commit }) {
      await signOut(auth);

      commit("CLEAR_USER");

      if (router.currentRoute.value.path == Routes.LANDING) {
        router.replace(Routes.LANDING);
      } else {
        router.replace(Routes.SIGNIN);
      }
    },
    async forgotPassword({ commit }, details) {
      const { email } = details;

      const res = await sendPasswordResetEmail(auth, email)
        .then(() => {
          commit("CLEAR_USER");
          router.replace(Routes.SIGNIN);
          return "Reset mail sent";
        })
        .catch((error) => {
          console.log(error);
          return error;
        });

      return res;
    },
  },
  modules: {},
});
