export enum AllowedEvents {
  SIGNED_IN = "SIGNED_IN",
  SIGNED_OUT = "SIGNED_OUT",
  IMAGE_SENT = "IMAGE_SENT",
  TEXT_SENT = "TEXT_SENT",
  FILE_SENT = "FILE_SENT",
  STARTED_CHECKOUT = "STARTED_CHECKOUT",
  COMPLETED_CHECKOUT = "COMPLETED_CHECKOUT",
  CANCELLED_CHECKOUT = "CANCELLED_CHECKOUT",
  CLICKED_WELCOME_OFFER = "CLICKED_WELCOME_OFFER",
  TEST = "TEST",
}
