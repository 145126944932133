
import { ref, onMounted, onBeforeMount } from "vue";
import { isMobile } from "./Auth/isMobile";

export default {
  name: "VideoSection",
  components: {},
  setup() {
    const scrollPosition = ref(0);
    function updateScroll() {
      scrollPosition.value = window.scrollY;
    }

    const mobile = isMobile();

    const windowSize = ref(window.innerWidth);

    onBeforeMount(() => {
      windowSize.value = window.innerWidth;
    }),
      window.addEventListener("resize", () => {
        windowSize.value = window.innerWidth;
      });

    onMounted(() => {
      window.addEventListener("scroll", updateScroll);
    });

    const scrollToContent = () => {
      const anchor = document.querySelector("#scrolllHere");
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth" });
      }
    };

    return {
      scrollPosition,
      updateScroll,
      scrollToContent,
      mobile,
    };
  },
};
