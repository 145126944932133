const download = async (OSName: string) => {
  try {
    //https://github.com/Leija-Software-AB/shipd-desktop-deployment/releases/latest/download/Shipd_Desktop_Setup-${version}.exe

    const latest = await fetch(
      "https://api.github.com/repos/Leija-Software-AB/shipd-desktop-deployment/releases/latest"
    ).then((res) => res.json());

    const version = latest.tag_name.replace("v", "");

    const fileName =
      OSName == "MacOS"
        ? `Shipd-Desktop-${version}.dmg`
        : `Shipd-Desktop-Setup-${version}.exe`;

    const url =
      `https://github.com/Leija-Software-AB/shipd-desktop-deployment/releases/latest/download/` +
      fileName;

    window.open(url, "_blank");
  } catch (error) {
    console.log(error);
  }
};

export default download;
