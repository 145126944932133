<template>
  <div class="sign-in-wrapper">
    <img src="~@/assets/shipd.svg" alt="logotyp" />
    <form>
      <h3 v-show="showEmailSent" class="success">Email sent!</h3>
      <h3 v-show="showError != ''" class="error">
        Unable to send email, please try again later.
      </h3>
      <h3>We have sent a verification email to:</h3>
      <h4>{{ email }}</h4>
      <button class="isSupervisor" type="button" @click="sendEmail">
        Didn't receive an email? Click here to resend.
      </button>
    </form>
  </div>
</template>

<script>
import { ref } from "vue";
import { auth } from "../../firebase";
import { sendEmailVerification } from "firebase/auth";

export default {
  name: "RegisterVerifyEmail",

  setup() {
    const email = ref(auth.currentUser.email);
    const showEmailSent = ref(false);
    const showError = ref("");

    function sendEmail() {
      sendEmailVerification(auth.currentUser, {
        url: window.location.origin + "/signup/plan",
      })
        .then(() => {
          showError.value = "";
          showEmailSent.value = true;
        })
        .catch((error) => {
          console.log(error);
          if (error === "auth/too-many-requests") {
            showError.value = "Too many tries";
          } else {
            showError.value = error;
          }
        });
    }

    return {
      sendEmail,
      showEmailSent,
      showError,
      email,
    };
  },
  components: {},
};
</script>

<style scoped>
img {
  padding-top: 1rem;
  height: 5rem;
}
.sign-in-wrapper {
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: var(--dark-blue);
  font-family: Nunito;
}
.forms {
  display: flex;
  min-height: 100vh;
}
.error {
  color: var(--primary-red);
}
.success {
  color: var(--dark-blue);
}
form {
  flex: 1 1 0%;
  padding: 2rem 3rem 0rem;
  display: flex;
  flex-direction: column;
  height: fit-content;
}

p {
  font-size: 1rem;
}

h2 {
  font-size: 1.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0;
  margin-bottom: 2px;
  color: var(--primary-white);
}
h3 {
  font-size: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0 2rem;
  color: var(--primary-white);
}
h4 {
  font-size: 1.25rem;
}
input,
button.next,
button.isSupervisor {
  appearance: none;
  border: none;
  outline: none;
  background: none;
  text-decoration: underline solid var(--dark-blue);

  width: 100%;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  padding: 1rem 0rem;
  font-family: Nunito;
  color: var(--dark-blue);
}

@media screen and (max-width: 994px) {
  h3 {
    font-size: 1.5rem;
  }
  button.isSupervisor {
    font-size: 0.75rem;
  }
}
</style>
