<template>
  <div class="wrapper">
    <div class="text-wrapper">
      <h1>Oh, something went wrong.</h1>
      <p>We couldn't find the page you were searching for.</p>
    </div>
    <router-link to="/" class="tillbaka-txt">Back to home</router-link>
  </div>
</template>

<script>
export default {
  name: "NotFoundContent",
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  height: 100vh;
  text-align: left;
  color: var(--dark-blue);
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Nunito";
}
.text-wrapper {
  width: 50%;
}
a {
  color: var(--background-blue);
  text-decoration: none;
}
.tillbaka-txt {
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  background-color: var(--dark-blue);
  font-size: 1.25rem;
  margin: 2rem;
  border: 3px solid var(--dark-blue);
}
.tillbaka-txt:hover {
  background-color: var(--background-blue);
  color: var(--dark-blue);
}
@media screen and (min-width: 995px) {
  .text-wrapper {
    margin-top: 7%;
  }
}
@media screen and (max-width: 994px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .text-wrapper {
    width: 100%;
    margin-top: 0;
    text-align: center;
  }
  .tillbaka-txt {
    margin-top: 2rem;
  }
}
</style>
