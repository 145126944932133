<template>
  <div class="background">
    <div class="wrapper">
      <DownloadComp />
    </div>
  </div>
</template>

<script>
import { onBeforeMount } from "vue";
import { useStore } from "vuex";
import DownloadComp from "@/components/DownloadComp.vue";
import { event } from "vue-gtag";

export default {
  name: "LogIn",
  components: {
    DownloadComp,
  },
  setup() {
    const store = useStore();

    if (process.env.VUE_APP_IS_TEST != "true") {
      console.log("Google Analytics is enabled");
      event("conversion", {
        send_to: "AW-10951781605/0FdsCKLr-5cYEOXZm-Yo",
      });
    }

    return {};
  },
};
</script>

<style scoped>
img {
  height: 75px;
}
.background {
  background-color: var(--background-blue);
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  flex-direction: column;
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
