
import NavBar from "@/components/NavBar.vue";
import TosComp from "@/components/TosComp.vue";
import FooterSection from "@/components/FooterSection.vue";

import { ref, onMounted, onBeforeMount } from "vue";

export default {
  name: "LandingPage",
  components: {
    NavBar,
    TosComp,
    FooterSection,
  },
  setup() {
    const windowSize = ref({});
    const scrollPosition = ref(0);

    function updateScroll() {
      scrollPosition.value = window.scrollY;
    }

    onMounted(() => {
      window.addEventListener("scroll", updateScroll);
    });

    onBeforeMount(() => {
      windowSize.value = window.innerWidth;
    }),
      window.addEventListener("resize", () => {
        windowSize.value = window.innerWidth;
      });

    return {
      scrollPosition,
      updateScroll,
      windowSize,
    };
  },
};
