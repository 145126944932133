
import { Ref, ref } from "vue";

export default {
  name: "VerifyEmailOk",
  props: {
    error: {
      type: String,
      default: "Something went wrong, please try again later.",
    },
  },
  setup(props) {
    const error = ref(props.error);

    const getErrorMessage = (error: Ref<string>) => {
      switch (error.value) {
        case "auth/invalid-action-code":
          return "The action code is invalid. This can happen if the code is malformed, expired, or has already been used.";

        default:
          return "Something went wrong, please try again later.";
      }
    };

    const errMsg = getErrorMessage(error);

    return {
      errMsg,
    };
  },
};
