import { auth } from "@/firebase";
import { State } from "@/store/types/State";
import { AllowedEvents } from "@/types/AllowedEvents";
import { Store } from "vuex";

export const sendEvent = async (
  store: Store<State>,
  event: AllowedEvents,
  properties?: any
) => {
  const token = await auth.currentUser?.getIdToken();
  const plan = store.state.isPremium ? "premium" : "free";

  const res = await fetch(
    `https://europe-west3-${process.env.VUE_APP_PROJECT_ID}.cloudfunctions.net/analyticsv2?event=${event}&client=WEB&plan=${plan}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Accept-content": "application/json",
      },
      body: JSON.stringify({
        ...properties,
      }),
    }
  ).then((res) => res.json());

  console.log(res);
  return res;
};
