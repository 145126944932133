
import { ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { auth } from "@/firebase";
import LoadingScreen from "@/components/Modals/LoadingScreen.vue";
import VerifyEmailOk from "@/components/Auth/VerifyEmailOk.vue";
import UserActionInvalid from "@/components/Auth/UserActionInvalid.vue";
import ResetPassword from "@/components/Auth/ResetPassword.vue";

import { applyActionCode, verifyPasswordResetCode } from "firebase/auth";

export default {
  name: "EmailActionHandler",
  components: {
    LoadingScreen,
    VerifyEmailOk,
    UserActionInvalid,
    ResetPassword,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const isLoading = ref(true);
    const showOk = ref(false);
    const showError = ref("");
    const userEmail = ref("");

    if (route.query.oobCode) {
      const actionCode =
        typeof route.query.oobCode === "string" ? route.query.oobCode : "";
      const continueUrl =
        typeof route.query.continueUrl === "string"
          ? route.query.continueUrl
          : "";

      switch (route.query.mode) {
        case "resetPassword":
          // Display reset password handler and UI.
          console.log("resetPassword");
          verifyPasswordResetCode(auth, actionCode)
            .then((email) => {
              isLoading.value = false;
              userEmail.value = email;
            })
            .catch((error) => {
              console.log(error.code);
              isLoading.value = false;
              showError.value = error.code;
            });
          break;
        case "verifyEmail":
          // Display email verification handler and UI.
          applyActionCode(auth, actionCode)
            .then((res) => {
              isLoading.value = false;
              showOk.value = true;
              if (continueUrl) {
                setTimeout(() => {
                  window.location.href = continueUrl;
                }, 1500);
              }
            })
            .catch((error) => {
              // Code is invalid or expired. Ask the user to verify their email address again.
              console.log(error.code);
              isLoading.value = false;
            });
          break;
        default:
          // Error: invalid mode.
          isLoading.value = false;
          showError.value = "Inavlid mode";
          break;
      }
    } else {
      isLoading.value = false;
      console.log("no oobCode");
    }

    return {
      isLoading,
      showOk,
      showError,
      route,
      userEmail,
    };
  },
};
