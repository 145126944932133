
import SignUpComplete from "@/components/Auth/SignUpComplete.vue";

export default {
  name: "WelcomeView",
  components: {
    SignUpComplete,
  },
  setup() {
    return {};
  },
};
