<template>
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
  />
  <vue-cookie-accept-decline
    class="cookies-banner"
    :debug="false"
    :disableDecline="true"
    elementId="cookieBanner"
    type="floating"
    :forceCookies="true"
  >
    <template #message>
      <p>
        We use cookies to ensure you get the best experience on our website.
        <br />
        <router-link class="cookies-a-tag" to="/cookies"
          >Read more...</router-link
        >
      </p>
    </template>
  </vue-cookie-accept-decline>
  <router-view />

  <div class="banner" v-if="showBanner == 'true'">
    <p>Shipd test site, NOT for production use</p>
  </div>

  <a class="discord" target="_blank" href="https://discord.gg/srqnqdaaMu">
    <span class="tooltip">Talk to the Devs, Join our Discord!</span>
    <img src="~@/assets/icon_clyde_white_RGB.svg" alt="discord logo" />
  </a>
</template>

<script>
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  setup() {
    const showBanner = process.env.VUE_APP_IS_TEST;
    const store = useStore();
    const route = useRoute();

    store.dispatch("fetchUser");

    return {
      showBanner,
    };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

:root {
  --dark-blue: #06283d;
  --background-blue: #dff6ff;
  --light-blue: #a4daff;
  --blue: #1363df;
  --primary-red: #e96767;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
body {
  background: var(--background-blue);
  margin: 0;
  font-family: "Nunito";
  z-index: 0;
  height: fit-content;
  width: 100vw;
  overflow-x: hidden;
  position: relative;
}
.banner {
  background: var(--primary-red);
  color: white;
  padding: 1px;
  font-family: "Poppins";
  font-size: 0.75rem;
  text-align: center;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
  position: fixed;
}
@media screen and (min-width: 995px) {
  .cookies-banner {
    padding: 1px;
    font-family: "Nunito";
    text-align: center;
    bottom: 50px;
    left: 50px;
    width: 300px;
    z-index: 999999;
    position: fixed;
    color: var(--dark-blue);
    background: var(--background-blue);
    border-radius: 0.5rem;
    box-shadow: 0 0 0.5rem 0.5rem rgb(0 0 0 / 0.2);
    border: var(--dark-blue) 1px solid;
  }
  a.cookies-a-tag {
    color: var(--dark-blue);
    width: 25%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 994px) {
  .cookies-banner {
    padding: 5px;
    font-family: "Nunito";
    text-align: center;
    bottom: 50px;
    left: 15px;
    right: 15px;
    z-index: 999999;
    position: fixed;
    color: var(--dark-blue);
    background: var(--background-blue);
    border-radius: 0.5rem;
    box-shadow: 0 0 0.5rem 0.5rem rgb(0 0 0 / 0.2);
    border: var(--dark-blue) 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a.cookies-a-tag {
    color: var(--dark-blue);
    width: 25%;
    margin: 0 auto;
  }
  p {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.discord {
  background-color: #5865f2;
  position: fixed;
  bottom: 2rem;
  right: 0rem;
  margin: 1rem;
  z-index: 100;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.discord:hover {
  transform: scale(1.1);
}
.discord img {
  width: 75%;
  height: auto;
}
.tooltip {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 7.5%;
  left: -125%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}
.discord:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
</style>
