<template>
  <div class="background">
    <div class="wrapper">
      <SignUpPlan />
    </div>
  </div>
</template>

<script>
import SignUpPlan from "@/components/Auth/SignUpPlan.vue";

export default {
  name: "LogIn",
  components: {
    SignUpPlan,
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
img {
  height: 75px;
}
.background {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  flex-direction: column;
}
.legal-wrapper {
  margin-left: 0.5rem;
}
.legal {
  color: var(--dark-blue);
  opacity: 50%;
  text-decoration: none;
  padding: 0.5rem;
  padding-bottom: 0;
  font-size: 0.75rem;
  text-align: center;
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
