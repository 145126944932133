<template>
  <div class="modal-background">
    <div class="modal">
      <SignUpPlan :existingPlan="subscription" />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import SignUpPlan from "../Auth/SignUpPlan.vue";

export default {
  name: "ManageAccount",
  props: ["subscription"],
  setup(props) {
    const isLoading = ref(false);
    const store = useStore();

    return {
      isLoading,
    };
  },
  components: { SignUpPlan },
};
</script>

<style scoped>
.modal-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  height: 75vh;
  width: 95vw;
  background-color: var(--background-blue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 0.5rem;
  color: var(--dark-blue);
  font-family: Nunito;
  box-shadow: 4px 0px 75px -22px rgba(0, 0, 0, 0.51);
  -webkit-box-shadow: 4px 0px 75px -22px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 4px 0px 75px -22px rgba(0, 0, 0, 0.51);
  text-align: center;
}
h1 {
  padding: 1rem;
  font-size: 1.5rem;
}
@media screen and (max-width: 994px) {
  .modal {
    height: 60vh;
    width: 95vw;
  }
}
</style>
