
import { ref } from "vue";
import { useStore } from "vuex";
import { isMobile } from "./isMobile";
import { auth } from "@/firebase";
import { getRedirectResult } from "firebase/auth";
import router from "@/router";

export default {
  name: "SignInBox",

  setup() {
    const login_form = ref({
      email: "",
      password: "",
    });

    getRedirectResult(auth).then((result) => {
      if (!result) {
        return;
      }

      const user = result.user;
      store.commit("SET_USER", user);
      router.replace("/signup/plan");
    });

    const store = useStore();

    const regPassMail = ref(false);

    const device = isMobile();

    const signIn = (provider: string) => {
      let obj = {
        provider: provider,
        device: device,
      };
      store.dispatch("signinProvider", obj);
    };

    const login = () => {
      store.dispatch("login", login_form.value);
    };

    return {
      login_form,
      login,
      signIn,
      regPassMail,
    };
  },
};
