
import { isMobile } from "@/components/Auth/isMobile";
import { ref } from "vue";

export default {
  name: "RedirectMobilePage",
  setup() {
    // get link from params
    const urlParams = new URLSearchParams(window.location.search);
    const link = urlParams.get("link");
    const h1Tag = ref("Redirecting...");

    const redirect = () => {
      if (!link) {
        console.log("No link");
        return;
      }

      // get device type
      if (isMobile()) {
        console.log("Mobile");
        // check if ios or android
        if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
          console.log("iOS");
          // redirect to app store
          window.location.href = link;
        } else if (navigator.userAgent.match(/Android/i)) {
          console.log("Android");
          // redirect to play store, this probably doesnt work
          window.location.href = link;
        }
        setTimeout(() => {
          h1Tag.value = "App opened, this tab can be closed now";
        }, 5000);
      } else {
        console.log("Desktop");
        // redirect to link
        window.location.href = link;
        setTimeout(() => {
          h1Tag.value = "App opened, this tab can be closed now";
        }, 5000);
      }

      // redirect to link
    };
    redirect();

    return { h1Tag };
  },
};
