
import { ref, onBeforeMount, onMounted } from "vue";

export default {
  name: "NavBar",
  setup() {
    const showNavbar = ref(false);
    const windowSize = ref(0);
    const scrollPosition = ref(0);

    function toggleNavbar() {
      showNavbar.value = !showNavbar.value;
    }

    function updateScroll() {
      scrollPosition.value = window.scrollY;
    }

    onMounted(() => {
      window.addEventListener("scroll", updateScroll);
    });

    onBeforeMount(() => {
      windowSize.value = window.innerWidth;
    }),
      window.addEventListener("resize", () => {
        windowSize.value = window.innerWidth;
        if (showNavbar.value) {
          showNavbar.value = false;
        }
      });

    return {
      showNavbar,
      toggleNavbar,
      windowSize,
      scrollPosition,
    };
  },
};
