
import { auth, db } from "@/firebase";
import router from "@/router";
import { TourStatus } from "@/store/types/TourStatus";
import { doc, setDoc } from "firebase/firestore";
import { ref } from "vue";
import { isMobile } from "./isMobile";
import download from "../getDownload";
import ToggleButton from "@/components/ToggleButton.vue";
import { Routes } from "@/types/Routes";

export default {
  name: "SignUpWelcome",
  components: { ToggleButton },
  setup() {
    const mobile = isMobile();

    const OSName = ref("Unknown OS");
    if (mobile) {
      if (navigator.userAgent.indexOf("Win") != -1) OSName.value = "Windows";
      if (navigator.userAgent.indexOf("Mac") != -1) OSName.value = "MacOS";
    }

    const skipWalktrough = () => {
      if (!auth.currentUser) return;
      setDoc(
        doc(db, "users", auth.currentUser.uid),
        {
          tourStatus: TourStatus.COMPLETE,
        },
        { merge: true }
      );
      router.replace(Routes.DOWNLOAD);
    };

    function toggleEvent(value: boolean) {
      if (typeof value === "boolean") {
        if (value) {
          OSName.value = "MacOS";
        } else {
          OSName.value = "Windows";
        }
      }
    }

    const continueTour = async () => {
      if (!auth.currentUser) return;
      await download(OSName.value);

      await setDoc(
        doc(db, "users", auth.currentUser.uid),
        {
          tourStatus: TourStatus.REMOTE_DOWNLOAD,
        },
        { merge: true }
      );
      setTimeout(() => {
        router.replace(Routes.SIGNUPREMOTEDOWNLOAD);
      }, 1000);
    };

    return {
      continueTour,
      mobile,
      OSName,
      toggleEvent,
      download,
      skipWalktrough,
    };
  },
};
