
import { auth } from "@/firebase";
import router from "@/router";
import { Routes } from "@/types/Routes";
import { getRedirectResult } from "firebase/auth";
import { ref } from "vue";
import { useStore } from "vuex";
import { isMobile } from "./isMobile";

export default {
  name: "SignUpMain",

  setup() {
    const register_form = ref({
      email: "",
      password: "",
      repeatPassword: "",
    });
    const store = useStore();
    const regState = ref(0);
    const regPassMail = ref(false);

    getRedirectResult(auth).then((result) => {
      if (!result) {
        return;
      }

      const user = result.user;
      store.commit("SET_USER", user);
      router.replace(Routes.SIGNUPPLAN);
    });

    const device: boolean = isMobile();

    const signIn = (provider: string) => {
      store.dispatch("signinProvider", { provider, device });
    };

    const register = () => {
      if (register_form.value.password === register_form.value.repeatPassword) {
        store.dispatch("register", register_form.value).then(() => {
          regState.value = 1;
        });
      } else {
        alert("Passwords do not match");
      }
    };
    return {
      register_form,
      register,
      regState,
      signIn,
      regPassMail,
    };
  },
  components: {},
};
