<template>
  <div class="background">
    <div class="wrapper">
      <SignUpVerify />
    </div>
  </div>
</template>

<script>
import SignUpVerify from "@/components/Auth/SignUpVerify.vue";

export default {
  name: "LogIn",
  components: {
    SignUpVerify,
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
img {
  height: 75px;
}
.background {
  background-color: var(--background-blue);
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  flex-direction: column;
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
