
import { ref, onMounted } from "vue";
import { marked } from "marked";


export default {
  name: "ReleaseNotesComp",
  setup() {
    const scrollPosition = ref(0);
    const showBanner = process.env.VUE_APP_IS_TEST;
    function updateScroll() {
      scrollPosition.value = window.scrollY;
    }

    onMounted(() => {
      window.addEventListener("scroll", updateScroll);
    });

    const markdownArray: any = ref([]);

    fetch("https://api.github.com/repos/Leija-Software-AB/shipd-desktop-deployment/releases").then((res) => res.json())
    .then((data) => {
      data.forEach((release) => {
        const note = marked.parse(release.body)
        markdownArray.value.push({note: note, version: release.tag_name})
      })
    })

    return {
      scrollPosition,
      updateScroll,
      showBanner,
      markdownArray,
    };
  },
};
