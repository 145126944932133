<template>
  <div class="background">
    <div class="wrapper">
      <SignUpMain />
      <p class="legal">
        By signing up and using our software you accept our terms
      </p>
      <div class="legal-wrapper">
        <router-link class="legal" to="/cookies">Cookies</router-link>
        <router-link class="legal" to="/tos">Terms of service</router-link>
        <router-link class="legal" to="/privacy">Privacy policy</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import SignUpMain from "@/components/Auth/SignUpMain.vue";
import { auth } from "@/firebase";
import { onBeforeMount } from "vue";
import { Routes } from "@/types/Routes";
import { useStore } from "vuex";

export default {
  name: "LogIn",
  components: {
    SignUpMain,
  },
  setup() {
    const store = useStore();

    onBeforeMount(async () => {
      if (auth.currentUser) await store.dispatch("routeChecker", Routes.SIGNUP);
    });

    return {};
  },
};
</script>

<style scoped>
img {
  height: 75%;
}
.background {
  background-color: var(--primary-gray);
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  flex-direction: column;
  overflow-y: hidden;
}
.legal-wrapper {
  width: 100%;
}
.legal {
  color: var(--dark-blue);
  opacity: 50%;
  text-decoration: none;
  padding: 0.5%;
  padding-bottom: 0;
  font-size: 0.75rem;
  text-align: center;
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-height: 99vh;
  box-sizing: border-box;
}
</style>
