
import SignUpThis from "@/components/Auth/SignUpThis.vue";

export default {
  name: "WelcomeView",
  components: {
    SignUpThis,
  },
  setup() {
    return {};
  },
};
