
import { ref, onMounted } from "vue";

export default {
  name: "EnterpriseForm",
  setup() {
    const scrollPosition = ref(0);
    const showBanner = process.env.VUE_APP_IS_TEST;
    function updateScroll() {
      scrollPosition.value = window.scrollY;
    }

    onMounted(() => {
      window.addEventListener("scroll", updateScroll);
    });

    return {
      scrollPosition,
      updateScroll,
      showBanner,
    };
  },
};
