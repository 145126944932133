
import { inject, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "UpsellScreen",
  props: {
    products: {
      type: Array,
      required: true,
    },
  },
  setup(props: any) {
    const isLoading = ref(false);
    const store = useStore();
    const { createSub }: any = inject("createSub");

    const upgrade = async () => {
      const product = props.products.find((p: any) => p.name === "Chief Mate");

      const price = product.prices.find(
        (p: any) => p.active === true && p.trial_period_days !== null
      );

      await createSub(price, product, true);
    };

    const skip = async () => {
      const product = props.products.find((p: any) => p.name === "Deck Cadet");

      const price = product.prices.find(
        (p: any) => p.active === true && p.trial_period_days == null
      );

      await createSub(price, product, true);
    };

    return {
      isLoading,
      upgrade,
      skip,
    };
  },
  components: {},
};
